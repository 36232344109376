import { ResumeConfig } from '../components/types';

/** 初始化常量 */
export const RESUME_INFO: ResumeConfig = {
  profile: {
    name: '纪元',
    email: '513544093@qq.com',
    mobile: '18943693383',
    workExpYear: '3年',
    expectJob: '服务运营',
    school: '长春工程学院',
  },
  awardList: [],
  workExpList: [
    {
      company_name: '蚂蚁集团（杭州）',
      department_name: '客户权益保障 - 安全风险审理',
      work_time: ['2020.03', null],
      work_desc:
        '1. 负责支付宝个人账户及企业账户的<strong>风险</strong>管控，涉及账户<strong>盗用风险</strong>，对客户账户进行风险识别、拦截、对案件进行审理及特征分析，协助优化风控策略能力\n2. 集<strong>用户痛点</strong>问题，反馈客户声音，发现产品、渠道、流程、服务等各维度上待提升<strong>体验点</strong>，结合数据指标，整合各方面资源进行推进优化，保证客户使用体验顺畅\n3. 出现舆情升级投诉时，进行应急处理，降低舆情风险\n',
    },
    {
      company_name: '美国道富银行（杭州）',
      department_name: '数据处理分析专员',
      work_time: ['2019.03', '2020.03'],
      work_desc:
        '1. 通过相关数据分析，整理成报表展现给客户，数据核查，防止发生客户资损风险\r\n2. 优化系统模型，使<strong>模型数据</strong>更加精准化对客户数据输出\n3. 灵活使用 <strong>Excel</strong>、<strong>SQL</strong> 等相关工具，对数据进行分类整理',
    },
  ],
  skillList: [
    {
      skill_name: '',
      skill_desc:
        '具有一定的数据思维，有数据分析相关经验，可独立产出数据图报表',
    },
    {
      skill_name: '',
      skill_desc: '熟练使用 Excel、SQL 数据软件，了解 Python 数据编程语言',
    },
    {
      skill_name: '',
      skill_desc:
        '英语 CET-4/6 良好的听说读写能力，快速浏览英语专业文件及书籍；可以流利使用商务英语进行对话',
    },
    {
      skill_name: '',
      skill_desc: '全国计算机二级考试 熟练运用 PPT、WORD、PS 等办公软件',
    },
  ],
  projectList: [
    {
      project_name: '绩效与数据项目',
      project_desc: '负责数据-迟滞链路优化，对接项目数据需求，建设数据看板\n',
      project_content:
        '对接的数据需求，产出可复用的 BI 数据看板。不断优化 SQL 语句和查询链路，提高数据分析效率',
      project_role: '数据分析提效',
      project_result: '数据产出时长从从 4 小时缩减至 10 分钟内',
    },
    {
      project_name: '地方政务网信息泄露案件',
      project_desc:
        '支付宝会员账户被授权在某政务网，黑产利用支付宝账户某链路环节，批量窃取会员信息\n',
      project_content:
        '识别挖掘风险，抽取异常数据，整理异常案件特征，反馈相关部门联动解决问题，迅速进行止血',
      project_role: '黑灰产批量案件挖掘',
      project_result: '获得服务有光 Top 1、无新增盗用举报',
    },
    {
      project_name: '钉钉红包被盗案件',
      project_desc:
        '支付宝会员无感知被盗，盗用者突破支付宝账户绑定钉钉账户，在钉钉群内给自己发专项红包，解绑支付宝再绑定其他支付宝账号提现\n',
      project_content:
        '识别账户风险，总结被盗案件数据特征，梳理黑产链路，定位问题，协同相关部门解决客户问题',
      project_role: '黑灰产批量案件挖掘',
      project_result: '',
    },
  ],
  workList: [],
  aboutme: {
    aboutme_desc:
      '具有较强的沟通能力、良好项目管理能力，善于<strong>联动协同</strong>多方资源解决风险问题\n具有一定的客户视角，快速洞察客户需求，对<strong>客户体验</strong>有一定见解思考\n具有<strong>产品</strong>思维，对产品问题提出建设性优化建议，优化产品问题案例多个',
  },
  theme: { color: '#2f5785', tagColor: '#8bc34a' },
};
